/* #region Fonts */
/* #endregion */

/* #region Variables */
$color-primary: #ec3237;
$color-dark: #101010;
/* #endregion */

/* #region Function */
@function rem-calc($size) {
  $remSize: $size / 16;
  @return #{$remSize}rem;
}
/* #endregion */

/* #region Global */
  html {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
  }
  body{
    font-family: 'Poppins', sans-serif;
    overflow-x:hidden;
    position: relative;
    color: #444;
  }
  *{outline: none!important;}
/* #endregion */

/* #region Typography */
  .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6{
    font-weight: 700;
    line-height: 1.1;
  }
  .h2, h2{
    font-size: rem-calc(36);
    margin-bottom: rem-calc(40);
    + .lead{
      margin:-30px 0 40px;
    }
  }
  .h3, h3{
    font-size: rem-calc(30);
    margin-bottom: rem-calc(16);
  }
  .h4, h4{
    font-size: rem-calc(20);
    margin-bottom: rem-calc(4);
  }
  p:last-child:not(.lead){margin-bottom: 0;}
  a{
    color: $color-primary;
    &:hover{color: $color-primary;}
  }

  .lead{font-weight: 400;}

  .text-primary{color: $color-primary!important;}
  .font-weight-medium{font-weight: 500;}

  .bg-gray{background-color: #f5f5f5;}
/* #endregion */
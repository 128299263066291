@media (max-width:1199px){
/* #region Page */
  .cover{
    .inner{max-width:600px}
    h1{font-size: rem-calc(42);}
    .lead{font-size: rem-calc(20);}
  }
  #modalView .modal-dialog{max-width: 930px;}
/* #endregion */
}

@media (max-width:991px){
/* #region Navbar */
  .navbar{
    padding-left: 20px;
    padding-right: 20px;
    &-brand{
      font-size: rem-calc(36);
    }
    &-nav{
      padding-bottom: rem-calc(20);
    }
    .nav-link{text-align: right;}
  }
  .navbar-collapse{
    position: absolute;
    right:0;
    top:100%;
    background-color: $color-dark;
    width:240px;
    box-shadow:0 1px 3px rgba(42,42,42,.2)
  }
  .navbar-link{
    font-weight: 600;
    padding:rem-calc(15) rem-calc(24);
  }
/* #endregion */

/* #region Page */
  .slick-arrow{
    left:0;
    &.slick-next{
      right:0;
    }
  }

  .card-deck{
    .card{
      flex: 0 0 50%;
      max-width: calc(50% - 30px);
      margin-bottom: 30px;
    }
  }
  .counter_wrap{
    padding-left: 0;
    padding-right: 0;
    .counter, .plus{
      font-size: rem-calc(32);
    }
    .h4, h4{
      font-size: rem-calc(16);
    }
  }
  .testimonial_inner{
    padding-left: 40px;
    padding-right: 40px;
  }
  .item_description{font-size: rem-calc(20);}
  .client_name{font-size: rem-calc(26);}
  #modalView .modal-dialog{max-width: 690px;}
/* #endregion */

}

@media (max-width:767px){

/* #region Page */
  .cover{
    background-position-x:40%!important;
    .inner{
      max-width: 340px;
    }
    p br{display: none;}
  }
  .card-deck{
    margin:0;
    .card{
      flex: 0 0 100%;
      max-width: 100%;
      margin-left:0;
      margin-right: 0;
    }
  }
  .counter_wrap{margin-bottom: 30px;}
  #portfolio .card-body{
    font-size: rem-calc(14);
    .h4, h4{font-size: rem-calc(18);}
  }
  #contact form{margin-top: rem-calc(40);}
  #modalView .modal-dialog{max-width: 510px;}
/* #endregion */

}

@media (max-width:576px){
/* #region Layout */
  .container{
    padding-left: 20px;
    padding-right: 20px;
  }
  .section{padding:rem-calc(40) 0;}
/* #endregion */

/* #region Typography */
  body{font-size: rem-calc(14);}
  .h2, h2{
    font-size: rem-calc(30);
    margin-bottom: rem-calc(24);
    + .lead{
      margin-top: rem-calc(-16);
    }
  }
  .h3, h3{font-size: rem-calc(26);}
  .h4, h4, .lead{font-size: rem-calc(18);}
/* #endregion */

/* #region Navbar */
  .navbar{
    min-height: 66px;
    &-brand{
      font-size: 30px;
      margin-right: 0;
    }
    &-link{
      display: none;
    }
  }
/* #endregion */

/* #region Page */
  .cover{
    align-items: flex-start;
    .inner{
      max-width: 280px;
      padding-top: 140px;
    }
    h1{font-size: rem-calc(36);}
    .lead{font-size: rem-calc(18);}
  }
  #testimonial{padding:rem-calc(70) 0;}
  .slick-arrow{
    left:-20px;
    &.slick-next{
      right:-20px;
    }
  }
  .testimonial_inner{
    padding-left: 20px;
    padding-right: 20px;
  }
  .item_description{font-size: 1rem;}
  .client_name{font-size: rem-calc(20);}
/* #endregion */

}

/* #region Layout */
  .section{
    padding:rem-calc(70) 0;
  }

  .slick{
    &-dots{
      list-style: none;
      padding: 0;
      margin: 0 0 10px;
      text-align: center;
      li{
        display: inline-block;
        position: relative;
        & + li{margin-left: 3px;}
        button{
          font-size: 0;
          height:5px;
          width:12px;
          padding: 0;
          background-color: #BDCCF5;
          border:0;
          outline: none!important;
          border-radius: 5px;
          transition: all .2s ease;
          &:hover{
            background-color: $color-primary;
          }
        }
        &.slick-active button{
          width:18px;
          background-color: $color-primary;
        }
      }
    }
    &-arrow{
      border:0;
      padding:0;
      position: absolute;
      top:calc(50% - 20px);
      left:-50px;
      width:40px;
      height: 40px;
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath d='M13.891 17.418c0.268 0.272 0.268 0.709 0 0.979s-0.701 0.271-0.969 0l-7.83-7.908c-0.268-0.27-0.268-0.707 0-0.979l7.83-7.908c0.268-0.27 0.701-0.27 0.969 0s0.268 0.709 0 0.979l-7.141 7.419 7.141 7.418z' fill='%23FFF'/%3E%3C/svg%3E") no-repeat center center;
      background-size: rem-calc(30) rem-calc(30);
      font-size: 0;
      color: transparent;
      z-index: 2;
      &.slick-next{
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath d='M13.25 10l-7.141-7.42c-0.268-0.27-0.268-0.707 0-0.979 0.268-0.27 0.701-0.27 0.969 0l7.83 7.908c0.268 0.271 0.268 0.709 0 0.979l-7.83 7.908c-0.268 0.271-0.701 0.27-0.969 0s-0.268-0.707 0-0.979l7.141-7.417z' fill='%23FFF'/%3E%3C/svg%3E");
        left:auto;
        right:-50px;
      }
      &.slick-disabled{
        opacity: .3;
      }
    }
  }

  .progress-bar{background-color: $color-primary;}

  .card{
    border-radius: 3px;
    border-color: #eee;
    box-shadow: 0 1px 4px rgba(107,69,69,.050);
    .h4, h4{line-height: 1.4;}
    &-img-wrap{
      position: relative;
      overflow: hidden;
      border-radius: 3px 3px 0 0;
      img{
        width: 100%;
        transition: transform .2s ease;
      }
      .open-link{
        opacity: 0;
      }
      &:hover{
        img{transform:scale(1.1);}
        .open-link{opacity: 1;}
      }
    }
  }
  .open-link{
    position: absolute;
    bottom:20px;
    right:20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border:1px solid #fff;
    width:34px;
    height:34px;
    color: #fff;
    box-shadow: 0 0 20px rgba(0,0,0,0.1);
    transition: all .2s ease;
    &:hover{
      background-color: #fff;
      color: $color-primary;
    }
  }

  .modal{
    &-dialog{
      min-height: calc(100vh - 4.875rem);
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-top: rem-calc(50);
    }
    &-content{
      border:0;
      border-radius: rem-calc(2);
      box-shadow: 0 0 10px 0 rgba(0,0,0,0.20);
    }
    .close{
      position: absolute;
      bottom:100%;
      right:0;
      font-size: rem-calc(40);
      line-height: 40px;
      text-shadow: none;
      font-weight: 400;
      color: #fff;
      opacity: 1!important;
    }
    .open-link{
      bottom:30px;
      right:30px;
    }
  }
/* #endregion */

/* #region Form */
  .form-control{
    border-radius: 0;
    border:2px solid #ccc;
    color: #444;
    font-weight: 500;
    height: calc(1.5em + 1.125rem + 4px);
    &::placeholder{
      font-weight: 400;
    }
    &:focus{
      border-color: #444;
      box-shadow: none;
    }
  }
  .btn{
    border-radius: 0;
    font-weight: 500;
    border-width: 2px;
    padding:rem-calc(9) rem-calc(24);
    &.focus, &:focus{
      box-shadow:none;
    }
    &-primary{
      background-color: $color-primary;
      border-color: $color-primary;
      &:hover, &:focus, 
      &:not(:disabled):not(.disabled).active, 
      &:not(:disabled):not(.disabled):active, 
      .show > &.dropdown-toggle{
        background-color: darken($color-primary, 10%);
        border-color: darken($color-primary, 10%);
      }
    }
    &-outline-white{
      background-color: transparent;
      border-color: #fff;
      color: #fff;
      &:hover, &:focus, 
      &:not(:disabled):not(.disabled).active, 
      &:not(:disabled):not(.disabled):active, 
      .show > &.dropdown-toggle{
        background-color: #fff;
        color: $color-primary;
      }
    }
  }

/* #endregion */

/* #region Navbar */
  .navbar{
    background-color: $color-dark;
    box-shadow: 0 1px 3px rgba(42,42,42,.2);
    padding-left: rem-calc(40);
    min-height: 70px;
    &-brand{
      font-size: 40px;
      font-weight: 700;
      color: #fff;
      line-height: 1;
      padding:0;
      &:hover{color: #fff;}
    }
    .nav-link{
      color: #fff;
      font-weight: 600;
      text-transform: uppercase;
      transition: color .2s ease;
      &:hover, &.nav-hireme{
        color: $color-primary;
      }
    }
    &-expand-lg .navbar-nav .nav-link{
      padding:rem-calc(15) rem-calc(24);
    }
  }
  .magic-hline{
    position: absolute!important;
    bottom:0;
    left: 0;
    width: 100px;
    height:2px;
    background: $color-primary;
    transform: translateX(0);
    transform-origin: left;
    transition: all 0.4s;
    z-index: 1;
  }
  .hamburger {
    $this: &;
    cursor: pointer;
    transition-property: opacity, filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin-right: -12px;
    overflow: visible;
    padding:rem-calc(15) rem-calc(12);
    &-box{
      width: 26px;
      height: 20px;
      display: block;
      position: relative;
    }
    &-inner{
      display: block;
      top: 50%;
      margin-top: -1px;
      &, &::before, &::after{
        width: 26px;
        height: 2px;
        background-color: #fff;
        position: absolute;
        transition-property: transform;
        transition-duration: 0.15s;
        transition-timing-function: ease;
      }
      &::before, &::after{
        content: "";
        display: block;
      }
      &::before {
        top: -6px; 
        transition: top 0.075s 0.12s ease,opacity 0.075s ease;
      }
      &::after{
        bottom: -6px;
        transition: bottom 0.075s 0.12s ease,
        transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
      }
      &{
        transition-duration: 0.075s;
        transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
      }
    }
    &.is-active{
      #{$this}-inner{
        transform: rotate(45deg);
        transition-delay: 0.12s;
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        &::before{
          top: 0;
          opacity: 0;
          transition: top 0.075s ease, opacity 0.075s 0.12s ease; 
        }
        &::after{
          bottom: 0;
          transform: rotate(-90deg);
          transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
        }
      }
    }
  }
/* #endregion */

/* #region Footer */
  .footer{
    padding: 30px 0;
    border-top: 1px solid #fafafa;
    color:#9e9e9e;
    font-size: rem-calc(14);
    text-align: center;
    overflow: hidden;
  }
/* #endregion */

/* #region Homepage */
  .cover{
    position: relative;
    background: url(../img/cover.jpg) no-repeat center center;
    background-size: cover;
    display: flex;
    align-items: center;
    color: #fff;
    height: 100vh;
    .inner{
      text-align: right;
      max-width: 700px;
      margin-left: auto;
    }
    h1{
      font-size: rem-calc(50);
      text-shadow: 0 0 40px rgba(0,0,0,.1);
      margin-bottom: rem-calc(16);
    }
    .lead{
      font-size: rem-calc(24);
      text-shadow: 0 0 10px rgba(0,0,0,.1);
      margin-bottom: rem-calc(30);
    }
  }
  .scroll-down{
    position: absolute;
    left:50%;
    bottom:40px;
    transform: translateX(-50%);
    color: #fff;
    transition: color .2s ease;
    animation-name:Floating;
    animation-duration:2s;
    animation-iteration-count:infinite;
    animation-timing-function:ease-in-out;
  }
  @keyframes Floating{
    from,to{transform:translate(-50%,0)}
    50%{transform:translate(-50%,20px)}
  }

  .nav-social{
    margin:0 -4px;
    li{margin:0 4px;}
    a{
      color: #444;
      &:hover{
        color: $color-primary;
      }
    }
  }

  #counter{
    position: relative;
    background: url(../img/project-counter.jpg) no-repeat center center;
    background-size: cover;
    padding:rem-calc(100) 0;
    &::before{
      position: absolute;
      content: '';
      background-color: rgba(0,0,0,.5);
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }
  .counter_wrap{
    border:1px solid rgba(#fff, .15);
    padding:rem-calc(24) 1rem;
    color: #fff;
    text-transform: uppercase;
    h4{
      color: $color-primary;
      font-weight: 600;
      margin-top: rem-calc(6);
    }
    .counter, .plus{
      font-size: rem-calc(42);
      font-weight: 600;
    }
  }
  .counter-icon{
    min-height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color-primary;
    margin-bottom: rem-calc(10);
  }

  #portfolio{
    .card{margin-bottom: 30px;}
    .card-body{
      a{color: #444;}
      .h4, h4{
        font-weight: 600;
        margin-bottom: rem-calc(8);
      }
    }
  }

  #modalView{
    .modal-dialog{
      max-width: 1110px;
    }
    .modal-content{
      border:0;
      border-radius: 3px;
    }
    .modal-body{
      padding:rem-calc(10);
    }
    img{width:100%;}
  }

  #testimonial{
    position: relative;
    background: url(../img/client-testimonial.jpg) no-repeat center center;
    background-size: cover;
    padding:rem-calc(100) 0;
    &::before{
      position: absolute;
      content: '';
      background-color: rgba(0,0,0,.7);
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }
  .testimonial_slider {
    max-width: 830px;
    margin:0 auto;
  }
  .testimonial_inner {
    color: #fff;
    cursor: move;
    position: relative;
  }
  .item_description{
    font-size: rem-calc(24);
  }
  .testimonial_slider .avtar {
    display: block;
    font-size: 250px;
    line-height: 100%;
    position: absolute;
    left: 50%;
    top: 0;
    transform:translateX(-50%);
  }
  .client_name {
    color: $color-primary;
    font-weight: 600;
    text-transform: capitalize;
  }
/* #endregion */
